<template>
  <v-card class="ma-2" style="text-align: center;">
    <page-title title="Logs" icon="mdi-database-settings"/>
    <v-container fluid grid-list-md px-0 py-0>
      <v-row>
        <v-col :key="key" md="3" sm="6" xs="12" v-for="(item,key ) in logItems">
          <dashboard-widget :widget-items="item"/>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import PageTitle from "../components/PageTitle";
import IsAuthenticated from "../middleware/IsAuthenticated";
import DashboardWidget from "../components/widget/DashboardWidget";
import Logs from "../services/Logs";
export default {
  name: "Reports",
  mixins: [IsAuthenticated],
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Reports | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {DashboardWidget, PageTitle},
  data() {
    return {
      logItems: [
        {
          title: 'Queued Jobs',
          type:'queued',
          value: 0,
          icon: 'mdi-comment-processing',
          color: 'rgba(247, 105, 33, 0.9)',
          action: '/queued-job-logs'
        },
        {
          title: 'Failed Jobs',
          type:'failedJobs',
          value: 0,
          icon: 'mdi-comment-alert',
          color: 'rgba(107, 117, 21, 0.9)',
          action: '/failed-queued-logs'
        },
        {
          title: 'Schedulers',
          type: 'taskSchedulers',
          value: 0,
          icon: 'mdi-folder-network',
          color: 'rgba(5, 22, 86, 0.9)',
          action: '/task-scheduler'
        },
        {
          title: 'Mail Box',
          type: 'mailBox',
          value: 0,
          icon: 'business',
          color: 'rgba(55,89,238,0.9)',
          action: '/mail-box'
        },
        {
          title: 'Error Logs',
          type: 'siteLogs',
          value: 0,
          icon: 'mdi-lock',
          color: 'rgba(247, 105, 33, 0.9)',
          action: '/site-logs'
        },
        {
          title: 'Newsletter Logs',
          type: 'newsletterLogs',
          value: 0,
          icon: 'mdi-contacts',
          color: 'rgba(107, 117, 21, 0.9)',
          action: '/newsletter-logs'
        },
        {
          title: 'Delivery Email Logs',
          type: 'deliveryEmailLogs',
          value: 0,
          icon: 'mdi-truck-delivery',
          color: 'rgba(5, 22, 86, 0.9)',
          action: '/delivery-email-logs'
        },
      ]
    }
  },
  mounted() {
    this.getJobHistory();
  },
  methods: {
    getJobHistory() {
      Logs.all({})
      .then(({data})=> {
        const {queued, failedJobs, taskSchedulers, mailBox, siteLogs, newsletterLogs, deliveryEmailLogs} = data;
       this.logItems = this.logItems.map((item) => {
          if(item.type === 'queued') {
            return {
              ...item,
              value: queued
            }
          } else if(item.type === 'failedJobs') {
            return {
              ...item,
              value: failedJobs
            }
          } else if(item.type === 'taskSchedulers') {
            return {
              ...item,
              value: taskSchedulers
            }
          } else if(item.type === 'mailBox') {
            return {
              ...item,
              value: mailBox
            }
          } else if(item.type === 'siteLogs') {
            return {
              ...item,
              value: siteLogs
            }
          }else if(item.type === 'newsletterLogs') {
            return {
              ...item,
              value: newsletterLogs
            }
          }else if(item.type === 'deliveryEmailLogs') {
            return {
              ...item,
              value: deliveryEmailLogs
            }
          }else {
            return item;
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.icon-text {
  font-size: 60px;
  color: white;
  .v-icon {
    font-size: 24px !important;
  }
}

.btn-text {
  font-size: 25px;
}

.widget-btn {
  width: 100%;
  padding: 0px;
  margin: 0px;
}
</style>